import { post } from "./api.js";
import { router } from "./router.js";
import { listenUpdateZotero } from "./views/auth/Account.js";
import {
  listenInitSentenceImport,
  listenImportSentence,
  listenRemoveArtificial,
  listenRevertSentence,
  listenCopyAnnotation,
  listenSaveSentence,
  listenTokenChanges,
} from "./views/text/tabs/editor.js";
import {
  listenExpandArethusa,
  listenCollapseArethusa,
  listenChangeArethusaLayer,
} from "./views/text/tabs/arethusa.js";
import {
  listenSaveXml,
  listenXmlChanges,
  listenToggleSentenceImport,
  listenConfirmTokenize,
} from "./views/text/tabs/xml.js";
import {
  listenShowAddTextType,
  listenAddTextType,
  listenDeleteTextType,
  listenAddAowPerson,
  listenDeleteAowPerson,
  listenEditAowPerson,
  listenEditAowPersonCertainty,
  listenOpenAowPersonDeleteModal,
  listenUpdateAowPersonId,
  listenAddPersonAndAssociation,
  listenEditAowPersonDetail,
  listenEditAowPersonRole,
} from "./views/text/tabs/metadata.js";
import {
  listenEditChapter,
  listenSaveChapter,
  listenAddChapter,
  listenChapterLinks,
  listenDeleteChapter,
  listenDeleteChapterConfirm,
  listenMdBlur,
} from "./views/grammar/Chapter.js";
import {
  listenTreeSearch,
  listenSliderInput,
  listenSearchNameInput,
  listenDeleteNode,
  listenAddNode,
  listenSwitchNodeLeft,
  listenSwitchNodeRight,
  listenSwitchNodeTop,
  listenSaveSearch,
  listenTextNode,
  listenShowSentenceTree,
} from "./views/search/Search.js";
import { listenUpdateLayerStatus } from "./views/text/tabs/workflow.js";
import { listenDownloadBackup } from "./views/text/tabs/archive.js";
import {
  listenEditPersonName,
  listenEditPersonTmId,
  listenEditPersonGender,
} from "./views/navigation/Person.js";
import { listenSmythLinks } from "./views/smyth/Smyth.js";
import { listenFrontFilter } from "./views/utils.js";

/* New version below*/

import { clickListeners as peopleClickListeners } from "./views/navigation/People.js";

const root = process.env.ROOT;

// Plaintext contenteditables (https://stackoverflow.com/a/64001839)
const insertTextAtSelection = (div, txt) => {
  //get selection area so we can position insert
  let sel = window.getSelection();
  let text = div.textContent;
  let before = Math.min(sel.focusOffset, sel.anchorOffset);
  let after = Math.max(sel.focusOffset, sel.anchorOffset);
  //ensure string ends with \n so it displays properly
  let afterStr = text.substring(after);
  if (afterStr == "") afterStr = "\n";
  //insert content
  div.textContent = text.substring(0, before) + txt + afterStr;
  //restore cursor at correct position
  sel.removeAllRanges();
  let range = document.createRange();
  //childNodes[0] should be all the text
  range.setStart(div.childNodes[0], before + txt.length);
  range.setEnd(div.childNodes[0], before + txt.length);
  sel.addRange(range);
  console.log(div.innerHTML);
};

export default (store) => {
  // History
  window.onpopstate = () => {
    router(location.pathname, store, 0);
  };

  // DOM content loaded
  /*
    document.addEventListener("DOMContentLoaded", () => {

    })
    */

  window.onload = () => {
    // Google login
    try {
      google.accounts.id.initialize({
        client_id:
          "139191467633-bojga6dr51j361aup3beikqkqcpnquf6.apps.googleusercontent.com",
        callback: (user) => {
          post(
            `/user/tokensignin`,
            { token: user.credential },
            { "Content-Type": "text/plain" }
          ).then((val) => {
            if ("token" in val) {
              localStorage.setItem("user", JSON.stringify(val));
              window.location =
                location.pathname == `${root}bye`
                  ? `${root}`
                  : location.pathname;
            }
          });
        },
      });

      google.accounts.id.renderButton(document.getElementById("login-link"), {
        type: "icon",
        size: "small",
        shape: "pill",
      });
    } catch {
      console.log("No internet");
    }

    // User login
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      document.getElementById("login-link").hidden = 1;
      document.getElementById(
        "user-mode"
      ).innerHTML = `<span class="badge-small small-caps">View</span>`;
      document.getElementById(
        "username"
      ).innerHTML = `<span class="badge-small badge-red small-caps">${user.user.level.at(
        -1
      )}</span>`;
      document.getElementById("signedin").hidden = 0;
    }

    // Route to requested page
    router(location.pathname, store, 0);
  };

  // Click
  document.addEventListener("click", (e) => {
    const t = e.target;

    /* !!! NEW VERSION !!! */

    const clickListeners = [].concat.apply([], [peopleClickListeners]);

    for (let l of clickListeners) {
      if (t.closest(l.e)) {
        l.f(t);
      }
    }

    /* !!! END NEW VERSION !!! */

    // All links
    if (t.closest("a")) {
      e.preventDefault();
    }
    if (t.closest("[data-link]")) {
      router(
        `${root}${t.closest("[data-link]").getAttribute("data-url")}`,
        store
      );
    }
    if (t.closest("[data-link-newtab]")) {
      const url = t.closest("[data-link-newtab]").getAttribute("data-url");
      window.open(`https://${window.location.hostname}${root}${url}`);
    }

    // Login
    if (t.closest("[logout]")) {
      localStorage.removeItem("user");
      window.location = `${root}bye`;
    } else if (t.closest("[login-link]")) {
      google.accounts.id.prompt();
    }

    // Modal
    if (t.closest(".modal")) {
      if (!t.closest(".modal-content")) {
        document.querySelectorAll(".modal").forEach((el) => {
          el.classList.add("d-none");
        });
      }
    }
    if (t.closest(".modal-close, .modal-cancel, .modal-save")) {
      document.querySelectorAll(".modal").forEach((el) => {
        el.classList.add("d-none");
      });
    }

    // Account
    if (t.closest(listenUpdateZotero[1])) {
      listenUpdateZotero[0](t);
    }

    // Chapter
    if (t.closest(".chapter-edit")) {
      listenEditChapter();
    }

    if (t.closest("#save-chapter")) {
      listenSaveChapter();
    }

    if (t.closest("#add-chapter")) {
      listenAddChapter();
    }

    if (t.closest("#delete-chapter-confirm")) {
      listenDeleteChapterConfirm(t);
    }

    if (t.closest("#delete-chapter")) {
      listenDeleteChapter(t);
    }

    if (t.closest(".chapter-link")) {
      listenChapterLinks(t);
    }

    // Smyth
    if (t.closest("#smyth-menu a, #smyth-main a")) {
      listenSmythLinks(t);
    }

    // Text editing
    if (t.closest("#save-xml")) {
      listenSaveXml();
    }

    if (t.closest("#expand-arethusa")) {
      listenExpandArethusa();
    }

    if (t.closest("#collapse-arethusa")) {
      listenCollapseArethusa();
    }

    // Token editor
    if (t.closest(".delete-artificial")) {
      listenRemoveArtificial(t);
    }

    if (t.closest(".import-sentence")) {
      listenInitSentenceImport(t);
    }
    if (t.closest(".try-import-xml")) {
      listenImportSentence(t, 0);
    }
    if (t.closest(".try-import-xml-force")) {
      listenImportSentence(t, 1);
    }

    if (t.closest(".revert-sentence")) {
      listenRevertSentence(t);
    }

    if (t.closest(".copy-all")) {
      listenCopyAnnotation(t);
    }

    if (t.closest(".copy-one")) {
      listenCopyAnnotation(t);
    }

    if (t.closest(".save-sentence")) {
      listenSaveSentence(t);
    }

    // Metadata
    if (t.closest(".add-text-type")) {
      listenShowAddTextType(t);
    }

    if (t.closest(".add-aow-text-type")) {
      listenAddTextType(t);
    }

    if (t.closest(".delete-text-type")) {
      listenDeleteTextType(t);
    }

    if (t.closest(".add-aow-person")) {
      listenAddAowPerson(t);
    }

    if (t.closest(".delete-aow-person-confirm")) {
      listenDeleteAowPerson(t);
    }

    if (t.closest(".delete-aow-person")) {
      listenOpenAowPersonDeleteModal(t);
    }

    if (t.closest(".edit-aow-person")) {
      listenEditAowPerson(t);
    }

    if (t.closest(".edit-aow-person-certainty")) {
      listenEditAowPersonCertainty(t);
    }

    if (t.closest(".update-person-id")) {
      listenUpdateAowPersonId(t);
    }

    if (t.closest(".add-person-and-association")) {
      listenAddPersonAndAssociation(t);
    }

    // XML editor
    if (t.closest(".import-sentence-toggle")) {
      listenToggleSentenceImport(t);
    }

    if (t.closest(".confirm-tokenization")) {
      listenConfirmTokenize(t);
    }

    // Workflow
    if (t.closest(".layer-button")) {
      listenUpdateLayerStatus(t);
    }

    // Archive
    if (t.closest(".download-backup")) {
      listenDownloadBackup(t);
    }

    // Search
    if (t.closest("#tree-search")) {
      listenTreeSearch(t);
    }
    if (t.closest(".del-node")) {
      listenDeleteNode(t);
    }
    if (t.closest(".add-node")) {
      listenAddNode(t);
    }
    if (t.closest(".sl")) {
      listenSwitchNodeLeft(t);
    }
    if (t.closest(".sr")) {
      listenSwitchNodeRight(t);
    }
    if (t.closest(".st")) {
      listenSwitchNodeTop(t);
    }
    if (t.closest("#save-search")) {
      listenSaveSearch(t);
    }
    if (t.closest(".show-tree")) {
      listenShowSentenceTree(t);
    }

    // Person
    if (t.closest(listenEditPersonGender[1])) {
      listenEditPersonGender[0](t);
    }
  });

  // Input change
  document.addEventListener("input", (e) => {
    const t = e.target;

    if (t.closest("#editable-xml")) {
      listenXmlChanges(t);
    }

    if (t.closest(".token-editable")) {
      listenTokenChanges(t);
    }

    if (t.closest(".aow-person-editable-detail")) {
      listenEditAowPersonDetail(t);
    }
  });

  // Radio and select change
  document.addEventListener("change", (e) => {
    const t = e.target;

    if (t.closest('[name="layer-arethusa"]')) {
      listenChangeArethusaLayer(t);
    }

    if (t.closest(".slider-input")) {
      listenSliderInput(t);
    }

    if (t.closest(".aow-person-role-select")) {
      listenEditAowPersonRole(t);
    }
  });

  // Keydown
  document.addEventListener("keydown", (e) => {
    const t = e.target;

    if (t.closest("#search-name")) {
      listenSearchNameInput(e);
    }

    if (e.key == "Enter") {
      if (t.closest("[contenteditable]")) {
        if (!e.target.classList.contains("noenter")) {
          //don't automatically put in divs
          e.preventDefault();
          e.stopPropagation();
          //insert newline
          insertTextAtSelection(e.target, "\n");
        } else {
          e.target.blur();
        }
      }
    }
  });

  // Keyup
  document.addEventListener("keyup", (e) => {
    const t = e.target;
    if (t.closest(".front-filter")) {
      listenFrontFilter(t);
    }
  });

  // Blur
  document.addEventListener(
    "blur",
    (e) => {
      const t = e.target;

      if (t.closest(".noenter")) {
        // Remove breaks
        if (t.querySelector("br")) {
          t.querySelector("br").remove();
        }
      }

      // Person
      if (t.closest(listenEditPersonName[1])) {
        listenEditPersonName[0](t);
      }

      if (t.closest(listenEditPersonTmId[1])) {
        listenEditPersonTmId[0](t);
      }

      // Chapter

      if (t.closest(listenMdBlur[1])) {
        listenMdBlur[0](t);
      }
    },
    true
  );

  // Paste
  document.addEventListener("paste", (e) => {
    e.preventDefault();
    //get plaintext from clipboard
    let text = (e.originalEvent || e).clipboardData.getData("text/plain");
    //insert text manually
    insertTextAtSelection(e.target, text);
  });
};
