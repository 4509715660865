import { setTitle } from "../utils.js";

export default () => {
  setTitle("Bye");

  const getHtml = () => {
    return `
        <section class="g-12 loner">You have been automatically signed out.</section>
    `;
  };
  const afterRender = () => {};

  return {
    getHtml,
    afterRender,
  };
};
