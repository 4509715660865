import NotFound from "./views/navigation/NotFound.js";
import Home from "./views/navigation/Home.js";
import Series from "./views/navigation/Series.js";
import Texts from "./views/navigation/Texts.js";
import Treebanks from "./views/navigation/Treebanks.js";
import Bye from "./views/auth/Bye.js";
import Expired from "./views/auth/Expired.js";
import Account from "./views/auth/Account.js";
import Text from "./views/text/Text.js";
import Search from "./views/search/Search.js";
import Chapter from "./views/grammar/Chapter.js";
import Authorship from "./views/research/Authorship.js";
import Privacy from "./views/navigation/Privacy.js";
import People from "./views/navigation/People.js";
import Person from "./views/navigation/Person.js";
import Smyth from "./views/smyth/Smyth.js";

let root = process.env.ROOT;

const routes = [
  { path: `${root}not-found`, view: NotFound },
  { path: `${root}`, view: Home },
  { path: `${root}series/:name`, view: Series },
  { path: `${root}texts`, view: Texts, cache: 1 },
  { path: `${root}treebanks`, view: Treebanks, cache: 1 },
  { path: `${root}bye`, view: Bye },
  { path: `${root}expired`, view: Expired },
  { path: `${root}account`, view: Account },
  { path: `${root}text/:id/:tab`, view: Text },
  { path: `${root}text/:id`, view: Text },
  { path: `${root}search/:id`, view: Search },
  { path: `${root}search`, view: Search },
  { path: `${root}grammar/:id`, view: Chapter },
  { path: `${root}grammar`, view: Chapter },
  { path: `${root}privacy`, view: Privacy },
  { path: `${root}research/authorship`, view: Authorship },
  { path: `${root}people`, view: People, cache: 1 },
  { path: `${root}person/:id`, view: Person },
  { path: `${root}smyth/:id`, view: Smyth },
  { path: `${root}smyth`, view: Smyth },
];

const getParams = (match) => {
  const values = match.result.slice(1);
  const keys = Array.from(match.route.path.matchAll(/:(\w+)/g)).map(
    (result) => result[1]
  );
  return Object.fromEntries(
    keys.map((key, i) => {
      return [key, values[i]];
    })
  );
};

let rendered = {};

const router = (url, store, addToHistory = 1) => {
  if (window.controller) {
    window.controller.abort();
  }

  window.controller = new AbortController();
  window.signal = window.controller.signal;

  if (addToHistory) {
    history.pushState(null, null, url);
  }

  const potentialMatches = routes.map((route) => {
    return {
      route: route,
      result: url.match(
        new RegExp(
          "^" + route.path.replace(/\//g, "\\/").replace(/:\w+/g, "(.+)") + "$"
        )
      ),
    };
  });

  const match = potentialMatches.find(
    (potentialMatch) => potentialMatch.result !== null
  ) || { route: routes[0], result: [url] };

  const cache = "cache" in match.route;

  const view =
    cache && url in rendered
      ? rendered[url]
      : match.route.view(store, getParams(match));

  if (cache) {
    rendered[url] = view;
  }

  document.querySelector("main").innerHTML = view.getHtml();
  view.afterRender();
};

export { router };
