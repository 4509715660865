import { root, setTitle, getUser, textTypes, aowRoles } from "../utils.js";
import { get, post } from "../../api.js";

import noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";

const datatableOptions = {
  perPage: 1000,
  perPageSelect: false,
  fixedColumns: false,
  data: {
    headings: [
      "Text",
      "Pos",
      "Orig",
      "Reg",
      "O Lemma",
      "O Postag",
      "O Rel",
      "R Lemma",
      "R Postag",
      "R Rel",
      "Y >",
      "< Y",
    ],
  },
  template: (options, dom) => `
    <div class='${options.classes.top}'>
      ${
        options.searchable
          ? `<div class='${options.classes.search}'>
              <input class='${options.classes.input}' placeholder='${
              options.labels.placeholder
            }' type='search' title='${options.labels.searchTitle}'${
              dom.id ? ` aria-controls="${dom.id}"` : ""
            }>
              </div>`
          : ""
      }
      ${options.paging ? `<div class='${options.classes.info}'></div>` : ""}
      <nav class='${options.classes.pagination}'></nav>
    </div>
    <div class='${options.classes.container}'${
    options.scrollY.length
      ? ` style='height: ${options.scrollY}; overflow-Y: auto;'`
      : ""
  }></div>
        <div class='${options.classes.bottom}'>
            ${
              options.paging
                ? `<div class='${options.classes.info}'></div>`
                : ""
            }
            <nav class='${options.classes.pagination}'></nav>
        </div>`,
  labels: {
    placeholder: "Filter...",
    perPage: "entries per page",
    noRows: "No tokens found",
    info: "{rows} tokens (showing {start}-{end})",
    noResults: "No results match your search query",
  },
};

export const listenShowSentenceTree = (t) => {
  console.log(t);
  let data = {
    layer: t.dataset.layer,
    text_id: t.dataset.textid,
    sentence_n: t.dataset.sentencen,
    token_id: t.dataset.tokenid,
  };
  post(`/search/get_sentence_tree`, data).then((val) => {
    if (val["ok"]) {
      let json = val["result"];
      let root = document.querySelector("#tf-tree2 > ul");

      root.innerHTML = jsonToHtml(json, "", t.dataset.tokenid);
      document.querySelector("#sentence-tree").classList.remove("d-none");
    }
  });
};

export const listenDeleteNode = (t) => {
  let cont = document.querySelector("#tf-tree > ul");
  if (cont.firstElementChild.isSameNode(t.closest("li"))) {
    return;
  }
  let childCount = t.closest("ul").children.length;
  let parent = t.closest("ul");
  t.closest("li").remove();
  if (childCount == 1) {
    parent.remove();
  }
};

export const listenAddNode = (t) => {
  let childUl = t.closest("li").getElementsByTagName("ul");
  if (childUl.length) {
    let li = createTreeLi();
    childUl[0].appendChild(li);
  } else {
    let ul = document.createElement("ul");
    ul.appendChild(createTreeLi());
    t.closest("li").appendChild(ul);
  }
};

const swapElements = (el1, el2) => {
  let el1Clone = el1.cloneNode(true);
  let el2Clone = el2.cloneNode(true);
  el2.parentNode.replaceChild(el1Clone, el2);
  el1.parentNode.replaceChild(el2Clone, el1);
};

export const listenSwitchNodeLeft = (t) => {
  swapElements(
    t.closest("span.tf-nc"),
    t.closest("li").previousSibling.firstElementChild
  );
};

export const listenSwitchNodeRight = (t) => {
  swapElements(
    t.closest("span.tf-nc"),
    t.closest("li").nextSibling.firstElementChild
  );
};

export const listenSwitchNodeTop = (t) => {
  swapElements(
    t.closest("span.tf-nc"),
    t.closest("ul").parentElement.firstElementChild
  );
};

export const listenSaveSearch = (t) => {
  saveSearch();
};

export const listenSearchNameInput = (e) => {
  if (e.key === "Enter") {
    e.preventDefault();
    saveSearch();
  }
};

export const listenSliderInput = (t) => {
  const slider = document.getElementById("slider");
  const handle = t.dataset.handle;
  slider.noUiSlider.setHandle(handle, t.value);
};

const sliderInit = () => {
  const slider = document.getElementById("slider");
  const input0 = document.getElementById("slider-dnb");
  const input1 = document.getElementById("slider-dna");
  const inputs = [input0, input1];
  noUiSlider.create(slider, {
    start: [-500, 1000],
    step: 1,
    connect: true,
    tooltips: false,
    animate: false,
    margin: 1,
    keyboardSupport: false,
    range: {
      min: -500,
      max: 1000,
    },
  });
  slider.noUiSlider.on("update", function (values, handle) {
    let val = Math.round(values[handle]);
    if (handle == 0) {
      if (val == -500) {
        inputs[handle].classList.add("disabled");
      } else {
        inputs[handle].classList.remove("disabled");
      }
    }
    if (handle == 1) {
      if (val == 1000) {
        inputs[handle].classList.add("disabled");
      } else {
        inputs[handle].classList.remove("disabled");
      }
    }
    inputs[handle].value = val;
  });
};

export const listenTreeSearch = () => {
  let msg = document.querySelector("#msg");
  let btn = document.querySelector("#tree-search");
  let info = document.querySelector(".info");
  info.innerHTML = "";
  msg.innerHTML = "";
  btn.innerHTML = 'Searching <span class="loader-small"/>';
  window.datatable.search("");
  window.datatable.destroy();
  window.datatable.init(datatableOptions);
  let dt = document.querySelector(".datatable-wrapper");
  dt.classList.add("d-none");
  dt.classList.remove("d-block");
  post(`/search/`, getSearch()).then((val) => {
    console.log(val);
    let layer = document.querySelector('input[name="layer"]:checked').value;
    if (val.ok) {
      if (val.result.length) {
        if (val.result.length > 10000) {
          info.innerHTML = `${val.result.length} tokens. (Upper limit for tabulation is 10000.)`;
        } else {
          let result = val["result"].map((item) => {
            return [
              `<a data-link-newtab href="${root}text/${item.text_id}" data-url="text/${item.text_id}">${item.name}</a>`,
              `<span data-sentencen="${item.sentence_n}" data-textid="${item.text_id}" data-layer="${layer}" data-tokenid="${item.id}" class="show-tree button button-plain button-small">${item.sentence_n}-${item.n}</span>`,
              item.orig_form || "",
              item.reg_form || "",
              item.orig_lemma || "",
              item.orig_postag || "",
              item.orig_relation || "",
              item.reg_lemma || "",
              item.reg_postag || "",
              item.reg_relation || "",
              item.date_not_before || "",
              item.date_not_after || "",
            ];
          });
          dt.classList.add("d-block");
          dt.classList.remove("d-none");
          window.datatable.insert({ data: result });
        }
      } else {
        info.innerHTML = `No results`;
      }
    } else {
      msg.innerHTML = val["detail"];
      msg.classList.remove("hidden");
    }
    btn.innerHTML = "Search";
  });
};

const createResultTreeLi = (txt = "", active) => {
  return `
    <li>
      <span class="tf-nc ${active ? "active" : ""}">
          ${
            txt.relation
              ? `<div><span class="res-relation">${txt.relation}</span></div>`
              : ""
          }
        <span class="res-text">${txt.form}</span>
      </span>
    `;
};

const createTreeLi = (txt = "") => {
  const els = [
    ["node-text", txt],
    ["add-node", "+"],
    ["del-node", "✖"],
    ["st", "↑"],
    ["sr", "→"],
    ["sl", "←"],
  ];
  let li = document.createElement("li");
  let spancontainer = document.createElement("span");
  spancontainer.classList.add("tf-nc");

  for (let el of els) {
    let s = document.createElement("span");
    s.classList.add(el[0]);
    if (!["node-text"].includes(el[0])) {
      s.classList.add("tf-b");
    }
    let t = document.createTextNode(el[1]);
    s.appendChild(t);
    if (["node-text"].includes(el[0])) {
      s.contentEditable = "true";
      s.classList.add("noenter");
    }
    spancontainer.appendChild(s);
  }
  li.appendChild(spancontainer);
  return li;
};

const jsonToHtml = (json, t, tokenId = 0) => {
  let li;
  if (tokenId) {
    li = createResultTreeLi(json["q"], tokenId == json["q"]["id"]);
  } else {
    li = createTreeLi(json["q"]).outerHTML.slice(0, -5);
  }

  t += li;
  if ("children" in json && json["children"].length) {
    t += "<ul>";
    for (let child of json["children"]) {
      t = jsonToHtml(child, t, tokenId);
    }
    t += "</ul>";
  }
  t += "</li>";
  return t;
};

const htmlToJson = (el, t) => {
  if (el.tagName == "UL") {
    if (!t.endsWith("{")) {
      t += ",";
    }
    t += '"children":[';
  }
  if (el.tagName == "LI") {
    t += "{";
    let str = el.querySelector(".node-text").innerText.replace(/\s/g, "");
    t += `"q":"${str}"`;
  }
  for (let child of el.children) {
    t = htmlToJson(child, t);
  }
  if (el.tagName == "LI") {
    t += "}";
    if (el.nextSibling) {
      t += ",";
    }
  }
  if (el.tagName == "UL") {
    t += "]";
  }
  return t;
};

const getSearch = () => {
  let ul = document.querySelector("#tf-tree > ul");
  let t = "{" + htmlToJson(ul, "", 0).slice(1) + "}";
  let q = JSON.parse(t)["children"][0];
  return {
    q: q,
    layer: document.querySelector('input[name="layer"]:checked').value,
    "series-type": document.querySelector('input[name="series-type"]:checked')
      .value,
    dna: document.querySelector("input#slider-dna").value,
    dnb: document.querySelector("input#slider-dnb").value,
    "text-type": document.querySelector("#text-type").value,
    "place-name": document.querySelector("#place-name").value,
    "text-status": document.querySelector('input[name="text-status"]:checked')
      .value,
    "person-role": document.querySelector("#person-role").value,
    "person-id": document.querySelector("#person-id").value,
    "person-certainty": document.querySelector(
      'input[name="person-certainty"]:checked'
    ).value,
  };
};

const updateSearchBadge = (id, name) => {
  document.getElementById("saved-search-name").innerHTML = name;
  document.getElementById("saved-search-id").innerHTML = id;
  document.getElementById("saved-search-badge").classList.remove("d-none");
};

const saveSearch = () => {
  let result = post(`/search/save`, {
    q: getSearch(),
    name: document.querySelector("#search-name").innerText,
    public: +document.getElementById("public").checked,
  });
  const saveBtn = document.getElementById("save-search");
  saveBtn.innerText = "Wait...";
  result.then((val) => {
    if ("id" in val) {
      if (val["new"] == 1) {
        history.pushState(null, null, `${root}search/${val["id"]}`);
        saveBtn.innerText = "Saved!";
      } else {
        saveBtn.innerText = "Updated!";
      }
      updateSearchBadge(
        val["id"],
        document.querySelector("#search-name").innerText
      );
      setTimeout(function (val) {
        saveBtn.innerText = "Save";
      }, 1000);
    }
  });
};

export default (store, params) => {
  setTitle("Search");
  let user = getUser();

  const dataTableInit = () => {
    return new simpleDatatables.DataTable("#datatable", datatableOptions);
  };

  const treeInit = () => {
    let root = document.querySelector("#tf-tree > ul");
    if (params.id) {
      let search = get(`/search/get_by_id/${params.id}`);
      search.then((val) => {
        if (val.ok && val.result.length) {
          let result = val.result[0];
          document.getElementById("search-name").innerText = result["name"];
          if (result["public"]) {
            document.getElementById("public").checked = true;
          }

          let json = JSON.parse(result["query"]);

          root.innerHTML = jsonToHtml(json["q"], "");
          document.getElementById(json["layer"]).checked = true;
          if ("series-type" in json) {
            document.getElementById(
              "series-type-" + json["series-type"]
            ).checked = true;
          }

          document.getElementById("text-type").value = json["text-type"];
          document.getElementById("person-role").value = json["person-role"];
          document.getElementById("person-id").value = json["person-id"];
          document.getElementById("place-name").value = json["place-name"];
          document.getElementById(
            "text-status-" + json["text-status"]
          ).checked = true;
          document.getElementById(
            "person-certainty-" + json["person-certainty"]
          ).checked = true;
          const dna = document.getElementById("slider-dna");
          const dnb = document.getElementById("slider-dnb");
          dna.value = json["dna"];
          dnb.value = json["dnb"];
          listenSliderInput(dna);
          listenSliderInput(dnb);
          updateSearchBadge(result["id"], result["name"]);
        } else {
          window.location.href = `${root}search`;
        }
      });
    }
    root.appendChild(createTreeLi());
  };

  const getHtml = () => {
    let userButtons = user ? `` : `hidden`;
    return `
      <h1>Search</h1>
      <section id="saved-search-badge" class="d-none centered">
        <span class="semi-bold">Saved search: </span><span id="saved-search-name" class="badge-small"></span> <span class="semi-bold" style="font-variant:small-caps">id: </span> <span class="badge-small badge-grey" id="saved-search-id"></span>
      </section>
      <section class="search">
        <div style="padding-bottom:20px;">
          <input checked type="radio" id="orig" name="layer" value="orig">
          <label for="orig">Original</label>
          <input type="radio" id="reg" name="layer" value="reg">
          <label for="reg">Regularized</label>
        </div>
        <div class="tf-tree tf-custom" id="tf-tree">
          <ul></ul>
        </div>
        <div style="padding:20px 0 0;">
          <div style="font-weight:500; padding-bottom:8px;">Place</div>
          <input style="max-width:30%;" type="text" id="place-name"/>
        </div>
        <div style="padding:10px 0;">
          <div style="font-weight:500">Dating</div>
          <div id="slider"></div>
          <div><input data-handle="0" class="slider-input" style="width:50px;" type="text" id="slider-dnb"/>
          <input data-handle="1" class="slider-input" style="width:50px;"  type="text" id="slider-dna"/></div>
        </div>
        <div style="padding:10px 0;">
          <label for="text-type"><span style="font-weight:500">Text type</span></label>
          <div class="select"><select name="text-type" id="text-type">
            <option value="">Any</option>
            ${textTypes()
              .map((t) => {
                return `<option value="${t[0]}">${t[1]}</option>`;
              })
              .join("")}
          </select></div>
          <span style="margin-left:10px;" class="semi-bold">Status </span>
          <input checked="" type="radio" id="text-status-" name="text-status" value="">
          <label for="text-status-">Any</label>
          <input type="radio" id="text-status-0" name="text-status" value="0">
          <label for="text-status-0">Normal</label>
          <input type="radio" id="text-status-1" name="text-status" value="1">
          <label for="text-status-1">Draft</label>
          <input type="radio" id="text-status-2" name="text-status" value="2">
          <label for="text-status-2">Copy</label>
        </div>
        <div style="padding:10px 0;">
          <label for="person-id"><span style="font-weight:500">Person <span style="font-variant:small-caps">id</span></span></label>
          <input value="" style="width:50px;" type="text" id="person-id"/>
          <label style="margin-left:5px;" for="person-role"><span style="font-weight:500">Role</span></label>
          <div class="select"><select name="person-role" id="person-role">
              <option value="">Any</option>
          ${aowRoles()
            .map((t) => {
              return `<option value="${t[0]}">${t[1]}</option>`;
            })
            .join("")}
          </select></div>
          <span style="margin-left:10px;" class="semi-bold">Certainty </span>
          <input checked="" type="radio" id="person-certainty-" name="person-certainty" value="">
          <label for="person-certainty-">Any</label>
          <input type="radio" id="person-certainty-0" name="person-certainty" value="0">
          <label for="person-certainty-0">Certain</label>
          <input type="radio" id="person-certainty-1" name="person-certainty" value="1">
          <label for="person-certainty-1">Uncertain</label>
        </div>
        <div style="padding:20px 0;">
          <input checked type="radio" id="series-type-documentary" name="series-type" value="documentary">
          <label for="series-type-documentary">Documentary papyri</label>
          <input type="radio" id="series-type-literary" name="series-type" value="literary">
          <label for="series-type-literary">Literary papyri</label>
          <input type="radio" id="series-type-inscription" name="series-type" value="inscription">
          <label for="series-type-inscription">Inscriptions</label>
          <input type="radio" id="series-type-" name="series-type" value="">
          <label for="series-type-">Any</label>
        </div>
        <div style="padding:30px 0;">
          <span id="tree-search" class="button">Search</span>
          <span class="userButtons ${userButtons}">
            <span id="save-search" class="button">Save</span>
            <span class="editable noenter" id="search-name" contenteditable="true"></span>
            <input type="checkbox" id="public" name="public">
            <label style="margin-left:6px;" for="public">Public</label>
          </span>
        </div>
        <div class="hidden" id="msg"></div>
        <div style="padding-bottom:20px;" class="info"></div>
        <div style="font-size:90%">
          <table id="datatable"></table>
        </div>
      </section>
      <div id="sentence-tree" class="modal d-none">
          <div class="tf-tree tf-result modal-content" id="tf-tree2">
          <a href="#" title="Close" class="modal-close"></a>
              <ul></ul>
          </div>
      </div>
      <div id="overlay" class="d-none"></div>
    `;
  };

  const afterRender = () => {
    window.datatable = dataTableInit();
    sliderInit();
    treeInit();
  };

  return {
    getHtml,
    afterRender,
  };
};
