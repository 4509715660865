import { setTitle, getUser, buttonDone, buttonWait } from "../utils.js";
import { get } from "../../api.js";
export const listenUpdateZotero = [
  (t) => {
    buttonWait(t);
    get(`/zotero/update_library`).then((val) => {
      if (!val.ok) {
        alert("An error happened. Sorry!");
      } else {
        buttonDone(t);
      }
    });
  },
  ".update-zotero",
];

export default () => {
  const user = getUser().user;
  setTitle(user.name);

  const getHtml = () => {
    console.log(user);
    return `
    <section>
      <h1>${user.name}</h1>
      <div class="centered g-14">
        <span class="badge-small">ID: ${user.id}</span> 
        <span class="badge-small">${user.email}</span> 
        <span class="badge-small">${user.level.at(-1)}</span>
      </div>
      <div class="centered g-14">
      ${
        user.level.includes("editor")
          ? `<span class="button button-small update-zotero">Update Zotero library</span>`
          : ``
      }
      </div>
      <div class="centered" style="margin-top:30px;">
        <a href="#" logout>Sign out</a>
      </div>
    </section>
    `;
  };
  const afterRender = () => {};

  return {
    getHtml,
    afterRender,
  };
};
