import { get } from "./api.js";
import { combineReducers, createStore } from "./redux.js";
import { getUser } from "./views/utils.js";
import counter from "./reducers/counter";
import painter from "./reducers/painter";
import initListeners from "./listeners";

const rootReducer = combineReducers({
  counter,
  painter,
});

const store = createStore(rootReducer);

setInterval(function () {
  if (getUser() != null) {
    get(`/user/ping`).then((val) => {});
  }
}, 60000);

initListeners(store);
