import { root, setTitle, centeredLoader, frontFilter } from "../utils.js";
import { get } from "../../api.js";

let cache;

export const clickListeners = [
  {
    e: ".expand-people",
    f: (t) => {
      getPersons(1);
    },
  },
];

export const listenExpandPeople = (t) => {
  getPersons();
};

const getPersons = (expanded = false) => {
  let container = document.querySelector("#people");
  container.innerHTML = centeredLoader();
  const query = expanded ? `/person/expanded` : `/person/expanded`;
  get(query).then((data) => {
    let result = "No persons.";
    if (data.ok && data.result.length) {
      result = data.result
        .map((el) => {
          const roles = el.roles
            ? el.roles
                .split(",")
                .map((role) => {
                  return ` <span class="badge-small">${role}</span>`;
                })
                .join("")
            : "";
          /*
          const authorTexts = (el.texts || "")
            .split(",")
            .map((t) => {
              return (t.split("|")[1] || "").replace(".xml", "");
            })
            .join("");
          console.log(authorTexts);
          */
          return `
            <div style="width:100%; break-inside: avoid-column; display:table;" class="tight filterable" data-filterval="${
              el.name + el.id
            }"><h3 style="margin-bottom:4px;margin-top:14px; ${
            expanded ? "font-weight:bold;" : ""
          }"><a href="${root}person/${el.id}" data-link data-url="person/${
            el.id
          }"><span>${
            el.name || "[Anonymous]"
          }</span></a> <span class="badge-small badge-grey">${el.id}</span>${
            expanded ? "" : roles
          }</h3>
    
          ${
            expanded
              ? (el.texts || "")
                  .split(",")
                  .map((t) => {
                    if (t) {
                      const tParts = t.split("|");
                      return `<span><a href="${root}text/${
                        tParts[2]
                      }" data-link data-url="text/${tParts[2]}">${
                        tParts[1]
                      }</a> <span class="badge-small">${
                        tParts[0]
                      }</span> <span data-uncertain="${
                        tParts[3] || 0
                      }" class="badge-small uncertainty-${
                        tParts[3] || 0
                      }"></span></span><br>`;
                    } else {
                      return "<span class='info'>No texts</span>";
                    }
                  })
                  .join("")
              : ""
          }
          </div>
       `;
        })
        .join("");
    }
    container.innerHTML = result;
    cache = result;
  });
};

export default (store, params) => {
  setTitle("People");

  const getHtml = () => {
    return `
      <h1>People in the Papyri</h1>
      ${frontFilter()}
      <!--<section class="centered"><span class="button button-small expand-people">Show texts</span></section>-->
      <section id="people" style="column-count: 2; padding-top:20px; margin:0 30px; text-align:center">
        ${cache ? cache : ""}
      </section>
    `;
  };
  const afterRender = () => {
    if (!cache) {
      getPersons(1);
    }
  };

  return {
    getHtml,
    afterRender,
  };
};
