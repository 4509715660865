import {get} from "../../../api.js"

export default async (doc, user) => {
    return get(`/text/${doc.id}/xml`).then((val) => {
        if (val.ok && val.result.length) {
            const xml = val.result
            return `
                <div class="card">
                <div class="card-header"><div class="card-title">Treebank XML</div></div>
                <div class="card-content xml"><xmp>${xml}</xmp></div>
                </div>
            `
        } else {
            return '<p style="text-align:center;">No treebank.</p>'
        }
    })
}