import {root, setTitle, loader, initTabs, getUser} from "../utils.js"
import {get} from "../../api.js"

export default (store, params) => {
    setTitle('Treebanks')
    let cache
    let user = getUser()

    const getTreebanks = (val, init=0) => {
        let container = document.querySelector('#treebanks')
        container.innerHTML = loader()
        let resultHTML = ''
        get(user ? `/texts/status/${val}` : `/texts/approved`).then((data) => {
            if (data.ok && data.result.length) {
                
                data.result.forEach(el => {
                    let status = ''
                    let style = ''
                    if (user) {
                        if (val == '3') {
                            if (el.orig_status != 3) {
                                status = 'O missing'
                            }
                            if (el.reg_status != 3) {
                                status += ' R missing'
                            }
                            if (status) {
                                style = 'color:red !important; font-weight:bold'
                            }
                        } else {
                            console.log(val.split(','))
                            console.log(el.orig_status)
                            if (val.split(',').includes(el.orig_status ? el.orig_status.toString() : '0')) {

                                status = 'O'
                            }
                            if (val.split(',').includes(el.reg_status ? el.reg_status.toString() : '0')) {
                                status += ' R'
                            }
                        }
                    }

                    let col = 'CUSTOM'
                    if (el.series_type == 'documentary') {
                        col = 'DDBDP'
                    } else if (el.series_type == 'literary') {
                        col = 'LITERARY'
                    } else if (el.series_type == 'inscription') {
                        col = 'INSCRIPTION'
                    }
                    resultHTML += `<p class="tight"><a style="${style}" href="${root}text/${el.id}" data-link data-url="text/${el.id}">${el.name}</a> <span class="badge-small">${col}</span> <span style="font-size:75%; color: grey">${status}</span></p>`
                })
            } else {
                resultHTML = 'No texts found.'
            }
            const containerVal = `
                <section class="info">${data.result.length} texts</section>
                <section id="treebanks" style="column-count: 3; padding-top:20px; margin:0 30px; text-align:center">${resultHTML}</section>
            `
            if (init) {cache = containerVal}

            container.innerHTML = containerVal
        })
    }

    const getHtml = () => {
        return `
            <h1>Treebanks</h1>
            <section>
                <div class="tabset">
                ${user ? 
                    `<div class="tabset-tabs">
                        <input type="radio" name="tabset" id="tab1" value="3" checked>
                        <label for="tab1">Finalized</label>
                        <input type="radio" name="tabset" id="tab2" value="6">
                        <label for="tab2">Submitted</label>
                        <input type="radio" name="tabset" id="tab3" value="1">
                        <label for="tab3">Active</label>
                        <input type="radio" name="tabset" id="tab4" value="8">
                        <label for="tab4">Migrated</label>
                        <input type="radio" name="tabset" id="tab5" value="2">
                        <label for="tab5">Rejected</label>
                        <input type="radio" name="tabset" id="tab6" value="update">
                        <label for="tab6">Pending update</label>
                        <input type="radio" name="tabset" id="tab7" value="v1">
                        <label for="tab7">v1</label>
                    </div>`: ''}
                    <section id="treebanks">
                    ${cache ? cache : loader()}
                    </section>
                </div>
            </section>
        `
    }
    const afterRender = () => {
        initTabs(getTreebanks)
        if (!cache) {getTreebanks("3", 1)}
    }
  
    return {
        getHtml,
        afterRender
    }
}