import { root, setTitle, loader, getUser, buttonDone } from "../utils.js";
import { get, post } from "../../api.js";
import cytoscape from "cytoscape";
import elk from "cytoscape-elk";
cytoscape.use(elk);

let menuCache = [];
const user = getUser();

export const listenChapterLinks = (t) => {
  const chapterId = t.dataset.chapterid;
  getChapter(chapterId);
  history.pushState(null, null, `${root}grammar/${chapterId}`);
};

export const listenEditChapter = () => {
  document.querySelector("#md").classList.remove("hidden");
  document.querySelector("#chapter").classList.add("hidden");
  document.querySelector("#md").focus();
};

export const listenSaveChapter = () => {
  saveChapter();
};

export const listenAddChapter = () => {
  let saveBtn = document.querySelector("#add-chapter");
  get(`/chapter/add`).then((val) => {
    if (val["ok"]) {
      buttonDone(saveBtn, "Wait...");
      window.location = `${root}grammar/${val.result}`;
    }
  });
};

export const listenDeleteChapter = (t) => {
  t.id = "delete-chapter-confirm";
  t.innerHTML = "Really?";
};

export const listenDeleteChapterConfirm = (t) => {
  post(`/chapter/delete`, { id: t.dataset.chapterid }).then((val) => {
    if (val["ok"]) {
      buttonDone(t, "Wait...");
      window.location = `${root}grammar`;
    } else {
      alert("This chapter has subchapters. Remove them before deleting.");
    }
  });
};

export const listenMdBlur = [
  (t) => {
    md.classList.add("wait");
    post(`/chapter/mdtohtml`, { md: md.innerHTML }).then((val) => {
      chapter.innerHTML = val["html"];
      md.classList.add("hidden");
      md.classList.remove("wait");
      chapter.classList.remove("hidden");
      saveChapter(0);
    });
  },
  ".md-edit",
];

const createResultTreeLi = (txt = "") => {
  return `
    <li>
      <span class="tf-nc">
        ${
          txt.relation
            ? `<div><span class="res-relation">${txt.relation}</span></div>`
            : ""
        }
      <span class="res-text">${txt.form}</span>
    </span>
    `;
};

const jsonToHtml = (json, t = "") => {
  let li;
  li = createResultTreeLi(json["q"]);
  t += li;
  if ("children" in json && json["children"].length) {
    t += "<ul>";
    for (let child of json["children"]) {
      t = jsonToHtml(child, t);
    }
    t += "</ul>";
  }
  t += "</li>";
  return t;
};

const formatTrees = () => {
  const classMapper = (postag) => {
    const colorMap = {
      l: "article",
      n: "noun",
      a: "adjective",
      p: "pronoun",
      v: "verb",
      d: "adverb",
      r: "preposition",
      c: "conjunction",
      i: "interjection",
      u: "punctuation",
      x: "irregular",
    };
    try {
      return colorMap[postag[0]];
    } catch {
      return "black";
    }
  };

  document.querySelectorAll(".sentence-tree").forEach((itm) => {
    itm.innerHTML = "";
    const error = "<span class='info'>[Failed to load sentence tree]</span>";
    let json = itm.dataset.json;
    if (json) {
      const q = JSON.parse(window.atob(json));

      let elements = [
        {
          data: { id: "node0", label: "[ROOT]" },
        },
      ];
      for (let n of q["result"]) {
        // Parent node shows the relation
        elements.push({
          data: {
            id: "node" + n["n"],
            label: n["relation"],
            color: "#555",
          },
          classes: "l1",
        });
        // Child node shows the form
        elements.push({
          data: {
            id: "lab" + n["n"],
            parent: "node" + n["n"],
            label: n["form"],
          },
          classes: classMapper(n["postag"]),
        });
      }

      for (let n of q["result"]) {
        elements.push({
          data: {
            id: "edge" + n["n"],
            source: "node" + n["head"],
            target: "node" + n["n"],
            label: n["relation"],
          },
        });
      }

      let cy = cytoscape({
        container: itm,
        autoungrabify: true,
        autounselectify: true,
        panningEnabled: true,
        elements: elements,

        layout: {
          name: "elk",
          elk: {
            algorithm: "layered",
            "elk.direction": "DOWN",
            "elk.layered.crossingMinimization.forceNodeModelOrder": true,
          },
          nodeDimensionsIncludeLabels: true,
          spacingFactor: 0.9,
        },
        style: [
          {
            selector: "node",
            css: {
              content: "data(label)",
              "background-color": "#fff",
              "text-outline-color": "#fff",
              "text-valign": "center",
              "text-background-color": "#fff",
              "text-outline-width": 3,
              "z-index": 12,
              "z-compound-depth": "top",
            },
          },
          {
            selector: ":parent",
            css: {
              "text-valign": "top",
              "text-halign": "center",
              "background-color": "#fff",
              color: "#555",
              "font-size": "11px",
              "border-color": "#fff",
              padding: "0px",
              shape: "roundrectangle",
              "z-index": 11,
              "z-compound-depth": "top",
            },
          },
          {
            selector: "edge",
            css: {
              width: 1,
              "line-color": "#333",
              "curve-style": "unbundled-bezier",
              "control-point-distances": (edge) =>
                edge.data("distances") || [0, 0],
              "control-point-weights": [0.25, 0.75],
              "z-index": 9,
            },
          },
          {
            selector: ".verb",
            css: {
              color: "red",
            },
          },
          {
            selector: ".pronoun",
            css: {
              color: "purple",
            },
          },
          {
            selector: ".adverb",
            css: {
              color: "darkorange",
            },
          },
          {
            selector: ".preposition",
            css: {
              color: "green",
            },
          },
          {
            selector: ".conjunction",
            css: {
              color: "deeppink",
            },
          },
          {
            selector: ".interjection",
            css: {
              color: "gold",
            },
          },
          {
            selector: ".irregular",
            css: {
              color: "gray",
            },
          },
          {
            selector: ".adjective",
            css: {
              color: "blue",
            },
          },
          {
            selector: ".article",
            css: {
              color: "lightblue",
            },
          },
          {
            selector: ".noun",
            css: {
              color: "#2b727c",
            },
          },
        ],
      });

      let adjustEdgeCurve = function (edge) {
        const { x: x0, y: y0 } = edge.source().position();
        const { x: x1, y: y1 } = edge.target().position();
        const x = x1 - x0;
        const y = y1 - y0;
        const z = Math.sqrt(x * x + y * y);
        const costheta = x / z;

        edge.style("control-point-distances", [-0.1 * y * costheta]);
        edge.style("control-point-weights", [0.5]);
      };

      cy.ready(() => {
        cy.edges().forEach(adjustEdgeCurve);
      });
    } else {
      itm.innerHTML = error;
    }
  });
};

const saveChapter = (reload = 1) => {
  const title = document.querySelector("#title");
  const chapterNumberContainer = document.querySelector("#chapter-number");
  const chapterParentContainer = document.querySelector("#chapter-parent");
  let saveBtn = document.querySelector("#save-chapter");

  post(`/chapter/save`, {
    md: document.querySelector("#md").innerHTML,
    html: document.querySelector("#chapter").innerHTML,
    id: title.dataset.chapterid,
    title: title.innerHTML,
    seq: chapterNumberContainer ? chapterNumberContainer.value : null,
    parent_id: chapterParentContainer ? chapterParentContainer.value : null,
  }).then((val) => {
    console.log(val);
    if (val["ok"]) {
      buttonDone(saveBtn);
      if (reload) {
        location.reload();
      } else {
        formatTrees();
      }
    }
  });
};

const getChapter = (chapterId) => {
  get(`/chapter/${chapterId}`).then((data) => {
    if (data.ok) {
      setTitle(data["result"]["title"]);
      document.querySelector("#chapter-main").innerHTML = `
        <h1 style="margin-top:4px; font-size:2rem; text-align:left;"><span id="path">${
          data["result"]["path"]
        }</span> <span ${
        user ? `contenteditable="true"` : ``
      } id="title" data-chapterid="${chapterId}">${data["result"]["title"]}
        </h1>
        ${
          user
            ? `<label for="chapter-parent">Parent chapter:</label>
        <div class="select"><select id="chapter-parent" name="parent"></select></div>
        <label for="chapter-number">Number:</label>
        <input name="chapter-number" id="chapter-number" type="text"></input>
        <p style="margin:10px 0;"><span class="button" id="save-chapter">Save</span>
        <span class="button" data-chapterid="${chapterId}" id="delete-chapter">Delete</span>
        <span class="button" id="add-chapter">New chapter</span></p>`
            : ``
        }
        <section style="margin-top:18px;" id="chapter" class="${
          user ? `chapter-edit` : ``
        }">
            ${data["result"]["html"]}
        </section>
        <pre contenteditable="true" id="md" class="hidden ${
          user ? `md-edit` : ``
        }" style="white-space:pre-wrap;word-wrap:break-word">${
        data["result"]["md"]
      }</pre>
        </section>
      `;
      if (user) {
        let seq = data["result"]["seq"];
        document.querySelector("#chapter-number").value = seq;
      }

      document.querySelectorAll(".menu-span").forEach((itm) => {
        itm.classList.remove("current");
      });
      document
        .querySelector(`.menu-span[data-chapterid="${chapterId}"]`)
        .classList.add("current");
      getParentSelector(chapterId, data["result"]["parent_id"]);

      formatTrees();
    }
  });
};

const getParentSelector = (chapterId, parentId) => {
  let selectContainer = document.querySelector("#chapter-parent");
  if (selectContainer !== null) {
    selectContainer.innerHTML += `
      <option value="" ${parentId ? `selected` : ""}>-</option>
    `;
    for (parent of menuCache) {
      if (parent.id != chapterId) {
        selectContainer.innerHTML += `
                      <option value="${parent.id}" ${
          parentId == parent.id ? `selected` : ""
        }>${parent.path.slice(1)} ${parent.title}</option>
                  `;
      }
    }
  }
};

const getSidebarMenu = () => {
  return get(`/chapter/get_menu`).then((data) => {
    if (data["ok"]) {
      let htmlStr = "";
      menuCache = data["result"];
      menuCache.forEach((el) => {
        htmlStr += `<span data-chapterid="${
          el.id
        }" class="menu-span" style="display:block; margin-left:${
          Math.max(el.level - 1, 0) * 20
        }px; margin-bottom:${
          el.level == 0 ? `0px` : ``
        }"><a href="${root}chapter/${
          el.id
        }" class="chapter-link" data-chapterid="${el.id}">${
          el.parent_id ? el.path.slice(1) : ""
        } ${el.title}</a></span>`;
      });
      document.querySelector("#menu").innerHTML = htmlStr;
    }
  });
};

export default (store, params) => {
  const getHtml = () => {
    return `
      <section style="grid-column: span 3">
        <h3 style="margin-top:10px;">Chapters</h3>
        <div id="menu">${loader()}</div>
      </section>
      <section id="chapter-main" style="padding: 0 40px; grid-column: 4 / span 10">
        ${loader()}
      </section>
      <svg id="svg-canvas"></svg>
  `;
  };

  const afterRender = () => {
    getSidebarMenu().then(() => {
      getChapter(params.id || 1);
    });
  };

  return {
    getHtml,
    afterRender,
  };
};
