import {get} from "../../../api.js"
import {root} from "../../utils.js"

export const listenDownloadBackup = (t) => {
    fetch(t.dataset.href, {method: 'get'})
        .then(res => res.blob())
        .then(res => {
            console.log(res)
            const aElement = document.createElement('a')
            aElement.setAttribute('download', 'treebank-backup.xml')
            const href = URL.createObjectURL(res)
            aElement.href = href
            aElement.setAttribute('target', '_blank')
            aElement.click()
            URL.revokeObjectURL(href)
        })
}

export default async (doc, user) => {
    return get(`/text/${doc.id}/archive`).then((val) => {
        if (val.ok && val.result.length) {
            return `
                <div class="centered">
                ${val.result.map((el) => {
                    return `<p><a class="download-backup" href="" data-href="${process.env.API}/text/${doc.id}/archive/${el.id}">${el.created}</a></p>` 
                }).join('')}
                </div>`
        } else {
            return '<p style="text-align:center;">No archived treebanks.</p>'
        }
    })
}