import {setTitle, loader, getUser} from "../utils.js"
import {post} from "../../api.js"

export default (store, params) => {
    setTitle('Authorship attribution')
    let cache
    let user = getUser()

    const data = {
        'katochoi': [
            'upz.1.2.xml',
            'upz.1.3.xml',
            'upz.1.5.xml',
            'upz.1.6.xml',
            'upz.1.7.xml',
            'upz.1.8.xml',
            'upz.1.9.xml',
            'upz.1.10.xml',
            'upz.1.11.xml',
            'upz.1.12.xml',
            'upz.1.13.xml',
            'upz.1.14.xml',
            'upz.1.15.xml',
            'upz.1.16.xml',
            'upz.1.17.xml',
            'upz.1.18r.xml',
            'upz.1.18v.xml',
            'upz.1.19.xml',
            'upz.1.20.xml',
            'upz.1.21.xml',
            'upz.1.22.xml',
            'upz.1.23.xml',
            'upz.1.24.xml',
            'upz.1.25.xml',
            'upz.1.26.xml',
            'upz.1.27.xml',
            'upz.1.28.xml',
            'upz.1.29.xml',
            'upz.1.30.xml',
            'upz.1.31.xml',
            'upz.1.32.xml',
            'upz.1.33.xml',
            'upz.1.34.xml',
            'upz.1.35.xml',
            'upz.1.36.xml',
            'upz.1.37.xml',
            'upz.1.38.xml',
            'upz.1.39.xml',
            'upz.1.40.xml',
            'upz.1.41.xml',
            'upz.1.42.xml',
            'upz.1.43.xml',
            'upz.1.44.xml',
            'upz.1.45.xml',
            'upz.1.46.xml',
            'upz.1.47.xml',
            'upz.1.48.xml',
            'upz.1.49.xml',
            'upz.1.50.xml',
            'upz.1.51.xml',
            'upz.1.52.xml',
            'upz.1.53.xml',
            'upz.1.54.xml',
            'upz.1.55.xml',
            'upz.1.56.xml',
            'upz.1.57.xml',
            'upz.1.58.xml',
            'upz.1.59.xml',
            'upz.1.60.xml',
            'upz.1.61.xml',
            'upz.1.62.xml',
            'upz.1.63.xml',
            'upz.1.64.xml',
            'upz.1.65.xml',
            'upz.1.66.xml',
            'upz.1.67.xml',
            'upz.1.68.xml',
            'upz.1.69.xml',
            'upz.1.70.xml',
            'upz.1.71.xml',
            'upz.1.72.xml',
            'upz.1.73.xml',
            'upz.1.74.xml',
            'upz.1.75.xml',
            'upz.1.76.xml',
            'upz.1.77.xml',
            'upz.1.78.xml',
            'upz.1.79.xml',
            'upz.1.80.xml',     
            'upz.1.111.xml',
            'upz.1.148.xml',    
        ],
        'zenon': [
            'p.cair.zen.1.59002.xml',
            'p.cair.zen.1.59016.xml',
            'p.cair.zen.1.59018.xml',
            'p.cair.zen.1.59019.xml',
            'p.cair.zen.1.59021.xml',
            'p.cair.zen.1.59024.xml',
            'p.cair.zen.1.59025.xml',
            'p.cair.zen.1.59027.xml',
            'p.cair.zen.1.59028.xml',
            'p.cair.zen.1.59029.xml',
            'p.cair.zen.1.59030.xml',
            'p.cair.zen.1.59031.xml',
            'p.cair.zen.1.59033.xml',
            'p.cair.zen.1.59034.xml',
            'p.cair.zen.1.59036.xml',
            'p.cair.zen.1.59038.xml',
            'p.cair.zen.1.59039.xml',
            'p.cair.zen.1.59040.xml',
            'p.cair.zen.1.59042.xml',
            'p.cair.zen.1.59043.xml',
            'p.cair.zen.1.59044.xml',
            'p.cair.zen.1.59045.xml',
            'p.cair.zen.1.59046.xml',
            'p.cair.zen.1.59047.xml',
            'p.cair.zen.1.59048.xml',
            'p.cair.zen.1.59049.xml',
            'p.cair.zen.1.59053.xml',
            'p.cair.zen.1.59056.xml',
            'p.cair.zen.1.59057.xml',
            'p.cair.zen.1.59058.xml',
            'p.cair.zen.1.59059.xml',
            'p.cair.zen.1.59060.xml',
            'p.cair.zen.1.59061.xml',
            'p.cair.zen.1.59066.xml',
            'p.cair.zen.1.59068.xml',
            'p.cair.zen.1.59074.xml',
            'p.cair.zen.1.59075.xml',
            'p.cair.zen.1.59077.xml',
            'p.cair.zen.1.59083.xml',
            'p.cair.zen.1.59085.xml',
            'p.cair.zen.1.59086.xml',
            'p.cair.zen.1.59093.xml',
            'p.cair.zen.1.59096.xml',
            'p.cair.zen.1.59099.xml',
            'p.cair.zen.1.59105.xml',
            'p.cair.zen.1.59107.xml',
            'p.cair.zen.1.59109.xml',
            'p.cair.zen.1.59110.xml',
            'p.cair.zen.1.59120.xml',
            'p.cair.zen.1.59121.xml',
            'p.cair.zen.1.59124.xml',
            'p.cair.zen.1.59125.xml',
            'p.cair.zen.1.59129.xml',
            'p.cair.zen.1.59130.xml',
            'p.cair.zen.1.59132.xml',
            'p.cair.zen.1.59134.xml',
            'p.cair.zen.1.59135.xml',
            'p.cair.zen.1.59136.xml',
            'p.cair.zen.2.59140.xml',
            'p.cair.zen.2.59142.xml',
            'p.cair.zen.2.59144.xml',
            'p.cair.zen.2.59145.xml',
            'p.cair.zen.2.59146.xml',
            'p.cair.zen.2.59147.xml',
            'p.cair.zen.2.59148.xml',
            'p.cair.zen.2.59150.xml',
            'p.cair.zen.2.59152.xml',
            'p.cair.zen.2.59154.xml',
            'p.cair.zen.2.59155.xml',
            'p.cair.zen.2.59156.xml',
            'p.cair.zen.2.59157.xml',
            'p.cair.zen.2.59159.xml',
            'p.cair.zen.2.59160.xml',
            'p.cair.zen.2.59161.xml',
            'p.cair.zen.2.59164.xml',
            'p.cair.zen.2.59166.xml',
            'p.cair.zen.2.59179.xml',
            'p.cair.zen.2.59180.xml',
            'p.cair.zen.2.59181.xml',
            'p.cair.zen.2.59184.xml',
            'p.cair.zen.2.59186.xml',
            'p.cair.zen.2.59188.xml',
            'p.cair.zen.2.59192.xml',
            'p.cair.zen.2.59199.xml',
            'p.cair.zen.2.59201.xml',
            'p.cair.zen.2.59202.xml',
            'p.cair.zen.2.59204.xml',
            'p.cair.zen.2.59215.xml',
            'p.cair.zen.2.59217.xml',
            'p.cair.zen.2.59220.xml',
            'p.cair.zen.2.59223.xml',
            'p.cair.zen.2.59225.xml',
            'p.cair.zen.2.59226.xml',
            'p.cair.zen.2.59229.xml',
            'p.cair.zen.2.59230.xml',
            'p.cair.zen.2.59236.xml',
            'p.cair.zen.2.59237.xml',
            'p.cair.zen.2.59240.xml',
            'p.cair.zen.2.59241.xml',
            'p.cair.zen.2.59243.xml',
            'p.cair.zen.2.59244.xml',
            'p.cair.zen.2.59245.xml',
            'p.cair.zen.2.59247.xml',
            'p.cair.zen.2.59251.xml',
            'p.cair.zen.2.59253.xml',
            'p.cair.zen.2.59254.xml',
            'p.cair.zen.2.59263.xml',
            'p.cair.zen.2.59264.xml',
            'p.cair.zen.2.59270.xml',
            'p.cair.zen.2.59273.xml',
            'p.cair.zen.2.59287.xml',
            'p.cair.zen.2.59288.xml',
            'p.cair.zen.2.59291.xml',
            'p.cair.zen.3.59408.xml',
            'p.cair.zen.3.59426.xml',
        ],
        'claudianus': [
            'o.claud.1.124.xml',
            'o.claud.1.125.xml',
            'o.claud.1.126.xml',
            'o.claud.1.127.xml',
            'o.claud.1.128.xml',
            'o.claud.1.129.xml',
            'o.claud.1.130.xml',
            'o.claud.1.131.xml',
            'o.claud.1.132.xml',
            'o.claud.1.134.xml',
            'o.claud.1.135.xml',
            'o.claud.1.137.xml',
            'o.claud.1.138.xml',
            'o.claud.1.139.xml',
            'o.claud.1.140.xml',
            'o.claud.1.141.xml',
            'o.claud.1.142.xml',
            'o.claud.1.143.xml',
            'o.claud.1.144.xml',
            'o.claud.1.145.xml',
            'o.claud.1.146.xml',
            'o.claud.1.147.xml',
            'o.claud.1.148.xml',
            'o.claud.1.149.xml',
            'o.claud.1.150.xml',
            'o.claud.1.151.xml',
            'o.claud.1.152.xml',
            'o.claud.1.153.xml',
            'o.claud.1.154.xml',
            'o.claud.1.155.xml',
            'o.claud.1.156.xml',
            'o.claud.1.157.xml',
            'o.claud.1.158.xml',
            'o.claud.1.159.xml',
            'o.claud.1.160.xml',
            'o.claud.1.161.xml',
            'o.claud.1.162.xml',
            'o.claud.1.163.xml',
            'o.claud.1.164.xml',
            'o.claud.1.165.xml',
            'o.claud.1.166.xml',
            'o.claud.1.167.xml',
            'o.claud.1.168.xml',
            'o.claud.1.169.xml',
            'o.claud.1.170.xml',
            'o.claud.1.171.xml',
            'o.claud.1.172.xml',
            'o.claud.1.173.xml',
            'o.claud.1.174.xml',
            'o.claud.1.175.xml',
            'o.claud.1.176.xml',
            'o.claud.1.177.xml',
            'o.claud.1.178.xml',
            'o.claud.2.224.xml',
            'o.claud.2.225.xml',
            'o.claud.2.226.xml',
            'o.claud.2.227.xml',
            'o.claud.2.228.xml',
            'o.claud.2.229.xml',
            'o.claud.2.230.xml',
            'o.claud.2.231.xml',
            'o.claud.2.232.xml',
            'o.claud.2.233.xml',
            'o.claud.2.235.xml',
            'o.claud.2.236.xml',
            'o.claud.2.237.xml',
            'o.claud.2.238.xml',
            'o.claud.2.239.xml',
            'o.claud.2.240.xml',
            'o.claud.2.241.xml',
            'o.claud.2.242.xml',
            'o.claud.2.243.xml',
            'o.claud.2.245.xml',
            'o.claud.2.246.xml',
            'o.claud.2.247.xml',
            'o.claud.2.248.xml',
            'o.claud.2.249.xml',
            'o.claud.2.252.xml',
        ],
        'philocles': [
            'o.krok.2.152.xml',
            'o.krok.2.153.xml',
            'o.krok.2.155.xml',
            'o.krok.2.156.xml',
            'o.krok.2.158.xml',
            'o.krok.2.160.xml',
            'o.krok.2.164.xml',
            'o.krok.2.167.xml',
            'o.krok.2.177.xml',
            'o.krok.2.182.xml',
            'o.krok.2.184.xml',
            'o.krok.2.189.xml',
            'o.krok.2.191.xml',
            'o.krok.2.193.xml',
        ]
    }

    const getInitData = () => {

        post(`/stats/authorship/`, data).then((data) => {
        
        })
    }

    const getHtml = () => {
        return `
            <h1>Authorship Attribution</h1>
            <section>
                ${loader()}
            </section>
        `
    }
    const afterRender = () => {
        getInitData()
    }
  
    return {
        getHtml,
        afterRender
    }
}