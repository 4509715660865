import {root, setTitle, centeredLoader, initTabs} from "../utils.js"
import {get} from "../../api.js"

export default (store, params) => {
    setTitle(params.name)
    let cache

    const getSeries = (val, init=0) => {
        get(`/series/type/${val}`).then((data) => {
            let fullResult = `
                <section class="info">${data.result.length} series</section>
                <section id="series" style="column-count: 3; padding-top:20px; margin:0 30px; text-align:center">
                ${data.result.map(el => {
                    let seriesName = encodeURIComponent(el['series_name'])
                    return `<p data-name="${seriesName}" class="tight"><a href="${root}series/${seriesName}" data-link data-url="series/${seriesName}">${el['series_name']}</a></p>`
                }).join('')}
                </section>
            ` 
            document.querySelector('#texts').innerHTML = fullResult
            get(`/series/type/${val}/counts`).then((countData) => {
                countData.result.forEach(el => {
                    let seriesName = encodeURIComponent(el['series_name'])
                    document.querySelector(`[data-name="${seriesName}"]`).insertAdjacentHTML('beforeEnd', ` <span class="badge-small">${el.text_count}</span>`)
                    if (init) { cache = document.querySelector('#texts').innerHTML }
                })
            })
        })
    }

    const getHtml = () => {
        return `
            <h1>Texts</h1>
            <section>
                <div class="tabset">
                    <div class="tabset-tabs">
                        <input type="radio" name="tabset" id="tab1" value="documentary" checked>
                        <label for="tab1">Documentary papyri (DDbDp)</label>
                        <input type="radio" name="tabset" id="tab2" value="literary">
                        <label for="tab2">Literary papyri</label>
                        <input type="radio" name="tabset" id="tab3" value="inscriptions">
                        <label for="tab3">Inscriptions</label>
                        <input type="radio" name="tabset" id="tab5" value="others">
                        <label for="tab5">Others</label>
                    </div>
                    <section id="texts">
                    ${cache ? cache : centeredLoader()}
                    </section>
                </div>
            </section>
        `
    }
    const afterRender = () => {
        initTabs(getSeries)
        if (!cache) {getSeries('documentary', 1)}
    }
  
    return {
        getHtml,
        afterRender
    }
}