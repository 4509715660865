import { root, setTitle, loader, initTabs, getUser } from "../utils.js";
import { get } from "../../api.js";

import getText from "./tabs/view.js";
import getEditor from "./tabs/editor.js";
import getMeta from "./tabs/metadata.js";
import getArethusa from "./tabs/arethusa.js";
import getWorkflow from "./tabs/workflow.js";
import getXml from "./tabs/xml.js";
import getTreebank from "./tabs/treebank.js";
import getArchive from "./tabs/archive.js";

export default (store, params) => {
  console.log(params);
  let doc;
  let user = getUser();
  const arethusa = require("../../../img/arethsa.png");

  const initShell = () => {
    return get(`/text/${params.id}`).then((data) => {
      if (data.ok) {
        document.querySelector("h1").innerHTML = data.result.name;
        document.querySelector("h1").dataset.textId = data.result.id;
        //document.querySelector('#temp').innerHTML = `python3 run.py try_to_merge ${data.result.name} <br> python3 run.py flag_ignore_and_merge ${data.result.name} ${params.id}`
        setTitle(data.result.name);
        document.querySelector("#document-meta").innerHTML = `
          [Metadata placeholder]
        `;
        document.querySelector("#document-meta").classList.remove("hidden");
        return data.result;
      }
    });
  };

  const tabFuncs = {
    "": getText,
    arethusa: getArethusa,
    workflow: getWorkflow,
    xml: getXml,
    editor: getEditor,
    metadata: getMeta,
    treebank: getTreebank,
    archive: getArchive,
  };

  const getTabView = (val) => {
    history.pushState(
      null,
      null,
      `${root}text/${params.id}${val ? "/" : ""}${val}`
    );
    renderTab(val);
  };

  const renderTab = (val) => {
    document.querySelector("#text").innerHTML = `${loader()}`;
    document.querySelector(`input[value="${val}"]`).checked = true;
    tabFuncs[val](doc, user).then((data) => {
      document.querySelector("#text").innerHTML = data;
    });
  };

  const getHtml = () => {
    return `
      <h1>${loader()}</h1>
      <div id="document-meta" class="hidden centered g-12"></div>
      <!--<section id="temp" style="width:100%; text-align:center"></section>-->
      <section>
        <div class="tabset">
          <div class="tabset-tabs">
            <input type="radio" name="tabset" id="tab1" value="">
            <label for="tab1">Text</label>
            <input type="radio" name="tabset" id="tab2" value="xml">
            <label for="tab2">Source XML</label>
            <input type="radio" name="tabset" id="tab3" value="treebank">
            <label for="tab3">Treebank XML</label>
            <input type="radio" name="tabset" id="tab4" value="arethusa">
            <label for="tab4"><span style="position:relative; top:2px; display:inline-block; width:80px; height:14px; background-image:url('${arethusa}'); background-size: contain; background-repeat: no-repeat"></span></label>
            <input type="radio" name="tabset" id="tab5" value="editor">
            <label for="tab5">Edit treebank</label>
            <input type="radio" name="tabset" id="tab6" value="metadata">
            <label for="tab6">Metadata</label>
            <input type="radio" name="tabset" id="tab7" value="workflow">
            <label for="tab7">Workflow</label>
            <input type="radio" name="tabset" id="tab8" value="archive">
            <label for="tab8">Archive</label>
          </div>
          <section id="text" style="padding-top:20px">
            ${loader()}
          </section>
        </div>
      </section>
      `;
  };
  const afterRender = (cache) => {
    initShell().then((data) => {
      doc = data;
      initTabs(getTabView);
      renderTab(params.tab || "");
    });
  };

  return {
    getHtml,
    afterRender,
  };
};
