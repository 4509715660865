const api = async (url, method, data = null, headers = null) => {
  let user = JSON.parse(localStorage.getItem("user"));
  try {
    let params = {
      headers:
        headers != null ? headers : { "Content-Type": "application/json" },
      mode: "cors",
      method: method,
      signal: window.signal,
    };
    if (data != null) {
      params["body"] = JSON.stringify(data);
    }
    if (user != null) {
      params["headers"]["Authorization"] = "Bearer " + user["token"];
    }
    const response = await fetch(process.env.API + url, params);
    let result = await response.json();
    if (response.ok) {
      return result;
    } else {
      if (response.status == 401) {
        localStorage.removeItem("user");
        window.location = `${process.env.ROOT}expired`;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const get = async (url) => api(url, "GET");
export const post = async (url, data, headers = null) =>
  api(url, "POST", data, headers);
export const patch = async (url, data, headers = null) =>
  api(url, "PATCH", data, headers);
export const del = async (url) => api(url, "DELETE");
