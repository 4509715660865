import {getUser} from "../../utils.js"

export const listenExpandArethusa = () => {
    document.querySelector('#arethusa').classList.add('full')
    document.querySelector('#collapse-arethusa').classList.remove('d-none')
}

export const listenCollapseArethusa = () => {
    document.querySelector('#arethusa').classList.remove('full')
    document.querySelector('#collapse-arethusa').classList.add('d-none')
}

export const listenChangeArethusaLayer = (t) => {
    const textId = document.querySelector('h1').dataset.textId
    document.querySelector('#arethusa').src=getArethusaUrl(textId, t.value, getUser())
}

export const getArethusaUrl = (docId, layer, user) => {
    let url
    if (user != null && user.user.level.length > 1) {
        url = process.env.ARETHUSA_EDIT
    } else {
        url = process.env.ARETHUSA_REVIEW
    }
    return `${url}?doc=${docId}&layer=${layer}`
}

export default async (doc, user) => {
    return `
        <span class="button d-none" style="z-index:2; transform: translate(-50%, 0);left:50%;position:fixed;top:16px" id="collapse-arethusa">Return to normal view</span>
        <section style="text-align: center;margin-bottom:16px;">
            <input checked="" type="radio" id="orig-arethusa" name="layer-arethusa" value="orig"/>
            <label for="orig-arethusa">Original</label>
            <input type="radio" id="reg-arethusa" name="layer-arethusa" value="reg"/>
            <label for="reg-arethusa">Regularized</label>
        </section>
        <div style="text-align:center"><span class="button" id="expand-arethusa">Full screen</span></div>
        <iframe id="arethusa" src="${getArethusaUrl(doc.id, 'orig', user)}"/>
    `
}