import {root, setTitle, listenClick, getEl} from "../utils.js"

export default (store, params) => {
    setTitle('Home')
    const logo = require("../../../img/papygreeklogo.jpg")
    const erc = require("../../../img/erc.png")
    const hy = require("../../../img/hy.png")

    const getHtml = () => {
        return `
            <section class="g-12">
                <h1 style="font-size:3rem;    background: -webkit-linear-gradient(top, firebrick, chocolate);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;">PapyGreek</h1>
                <h2>A platform for the linguistic study of Greek papyri, <br> including a <a class="semi-bold" href="${root}grammar" data-link data-url="grammar">grammar</a>, <a class="semi-bold" href="${root}treebanks" data-link data-url="treebanks">annotated texts</a>, and a <a class="semi-bold" href="${root}search" data-link data-url="search">search tool</a>.</h2>
            </section>
            <section class="g-12" style="text-align:center;">
            <img style="width:25%;" id="logo" src="${logo}"/>
            </section>
            <section class="g-12" style="text-align:center;">
                <img style="width:20%;" src="${erc}"/><br>
                <img style="width:10%" src="${hy}"/><br>
                <p style="font-size:12px; margin:10px 25%;">This project has received funding from the European Research Council (ERC) under the European Union’s Horizon 2020 research and innovation programme (grant agreement No 758481).</p> 
            </section>
            <!--
            <div style="width:100px;height:100px;background:grey;" id="count"></div>
            <div style="width:100px;height:100px;background:grey;" id="paint"></div>-->
        `
    }
    const afterRender = () => {
        /*
        const counterNode = document.getElementById('count')
        const painterNode = document.getElementById('paint')

        store.subscribe(['counter/count', () => {
            counterNode.innerHTML = store.getState().counter
        }])
        store.subscribe(['painter/paint', () => {
            painterNode.innerHTML = store.getState().painter
        }])

        listenClick('#count', (e) => {    
            store.dispatch({          
                type: 'counter/count',          
                value: 1,
            })
        })
        listenClick('#paint', (e) => {    
            store.dispatch({          
                type: 'painter/paint',          
                value: '',
            })
        })

        store.dispatch({})
        */
    }   
  
    return {
        getHtml,
        afterRender
    }
}